import React, {Component, Fragment} from 'react'
import './styles/testsmodal.css';
import patientJSON from './../assets/data/patientList';
import {AppBar, Chip, Grid, Icon, Paper, Snackbar, SnackbarContent, Tab, Tabs} from "@material-ui/core";
import {alltests as testsList} from "./../assets/data/testsList"
import {orthoDrugs as medList} from "./../assets/data/medicineList"
import IconButton from "@material-ui/core/IconButton";
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Select from "react-select";
import CanvasDraw from "react-canvas-draw";

export default class PatientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMode: 0,
            patientId: props.patientId,
            height: window.innerHeight,
            width: window.innerWidth,
            favouriteTests: [0, 1, 2, 3, 4],
            favouriteMeds: [0, 1, 2, 3, 4],
            prescribedTests: [],
            prescribedMeds: [],
            isFavInEditMode: false,
            isError: false,
            errorMessage: "",
        };
    }

    deleteFavourite(Id) {
        let favouries = (this.state.currentMode===0)?this.state.favouriteMeds:this.state.favouriteTests;
        let index = favouries.indexOf(Id);
        if (index > -1) {
            favouries.splice(index, 1);
        }
        this.setState((this.state.currentMode===0)?{favouriteMedicines: favouries}:{favouriteTests: favouries});
    }

    addPrescriptionOrFav = (selected) => {
        const id = selected.value;
        if(this.state.isFavInEditMode) {
            let currentFavourites = (this.state.currentMode === 0)? this.state.favouriteMeds : this.state.favouriteTests;
            if (currentFavourites.indexOf(id) === -1) {
                currentFavourites.push(id);
                this.setState((this.state.currentMode === 0) ? {favouriteMeds: currentFavourites} : {favouriteTests: currentFavourites});
            } else {
                this.setState({errorMessage: 'Already in Favourites!', isError: true})
            }
        } else {
            let currentPrescription = (this.state.currentMode === 0) ? this.state.prescribedMeds : this.state.prescribedTests;
            if (currentPrescription.indexOf(id) === -1) {
                currentPrescription.push(id);
                this.setState((this.state.currentMode === 0) ? {prescribedMeds: currentPrescription} : {prescribedTests: currentPrescription});
            } else {
                this.setState({errorMessage: 'Already Prescribed!', isError: true})
            }
        }
    };

    removePrescription = (id, mode) => {
        let currentPrescription = (mode===0)?this.state.prescribedMeds:this.state.prescribedTests;
        const index = currentPrescription.indexOf(id);
        if (index !== -1) {
            currentPrescription.splice(index, 1);
            this.setState((mode===0)?{prescribedMeds: currentPrescription}:{prescribedTests: currentPrescription});
        }
    };

    render() {
        const list = this.state.currentMode===0?medList:testsList;
        const options = list.map((item, i) => {
            return {value: i, label: item};
        });
        const patient = patientJSON[this.state.patientId];
        const favouriteChips = ((this.state.currentMode===0)?this.state.favouriteMeds:this.state.favouriteTests).map((id, i) => {
            return this.state.isFavInEditMode ?
                <Chip onDelete={() => {
                    this.deleteFavourite(id)
                }} label={list[id]} component='div'/>
                :
                <Chip onClick={() => {
                    this.addPrescriptionOrFav({value: this.state.favouriteTests[i]})
                }} label={list[id]} component='div'/>;
        });
        const prescribedMedChips = this.state.prescribedMeds.map((id) => {
            return <Fragment>
                <Chip onDelete={() => {
                    this.removePrescription(id, 0)
                }} label={medList[id]} component='div'/>
                <br/>
            </Fragment>
        });
        const prescribedTestsChips = this.state.prescribedTests.map((id) => {
            return <Fragment>
                <Chip onDelete={() => {
                    this.removePrescription(id, 1)
                }} label={testsList[id]} component='div'/>
                <br/>
            </Fragment>
        });
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.isError}
                    autoHideDuration={6000}
                    onClose={() => {
                        this.setState({isError: false})
                    }}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar">
          <Icon/>
                                {this.state.errorMessage}
        </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                                this.setState({isError: false})
                            }}>
                                <Close/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Paper style={{margin: 25, height: (this.state.height - 50), width: (this.state.width - 50)}}>
                    <Grid container component="div">
                        <Grid xs={6} component='div'>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.currentMode}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab onClick={()=>{if (this.state.currentMode === 1) this.setState({currentMode: 0})}} label="Prescription"/>
                                    <Tab onClick={()=>{if (this.state.currentMode === 0) this.setState({currentMode: 1})}} label="Test"/>
                                </Tabs>
                            </AppBar>
                                    <Paper style={{padding:10, height: (this.state.height - 150) }} square='true'>
                                        <p align="right" style={{margin: 0}}>
                                            <IconButton onClick={() => {
                                                this.state.isFavInEditMode ? this.setState({isFavInEditMode: false}) : this.setState({isFavInEditMode: true})
                                            }} aria-label="edit favourite">
                                                {
                                                    this.state.isFavInEditMode ? <Close/> : <Edit/>
                                                }
                                            </IconButton>
                                        </p>
                                        {favouriteChips}
                                        <br/><br/>
                                        <Select
                                            defaultValue={'Add Medicine'}
                                            label="Add Medicine"
                                            menuIsOpen="true"
                                            options={options}
                                            value=''
                                            onChange={this.addPrescriptionOrFav.bind(this)}
                                        />
                                    </Paper>
                        </Grid>
                        <Grid xs={6} component='div'>
                            <Paper style={{overflow:"auto", height: ((this.state.height - 50)/2) }} square='true'>
                                <Grid container component='div'>
                                    <Grid xs={6} component='div'>
                                        Prescription for {patient.name}<br/>
                                        {prescribedMedChips}
                                    </Grid>
                                    <Grid xs={6} component='div'>
                                        Tests for {patient.name}<br/>
                                        {prescribedTestsChips}
                                    </Grid>
                                </Grid>
                            </Paper>
                            <CanvasDraw brushRadius={1} immediateLoading={true} lazyRadius={1} loadTimeOffset={1}
                                style={{
                                    height: ((this.state.height - 50)/2),
                                    width: '100%',
                                    boxShadow:
                                        "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}
