import React, {Component} from 'react'
import './styles/data.css'
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import patientJSON from './../assets/data/patientList';
import image from "../assets/data/xray.png";
import "./styles/data.css"
import {Link} from "react-router-dom";


class Data extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Grid sm={12} component='div'>
                <Link to={`/dicom/${this.state.patientId}/1`}>
                    <Paper square='true'>
                        <div className="Post-image">
                            <div className="Post-image-bg">
                                <img width='inherit' src={image} alt={patient}/>
                            </div>
                        </div>
                        <div className="Post-caption">
                            <b>X-ray(Lungs)</b>
                            <small> by Dr. Vinay, Dr.Lal Pathology Labs</small>
                        </div>
                    </Paper>
                </Link>
            </Grid>
        );
    }
}

export default Data;
