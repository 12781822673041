import React, {Component} from 'react'
import {HashRouter, Route, Switch} from 'react-router-dom'
import {Landing, PageNotFound, PatientInfo,} from "./pages";
import Dmv from './components/dmv'

export default class Routes extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path='/' component={Landing}/>
                    <Route path='/patientInfo/:patientId' component={PatientInfo}/>
                    <Route path='/dicom/:patientId/:datumId' component={Dmv}/>
                    <Route path='*' component={PageNotFound}/>
                </Switch>
            </HashRouter>
        )
    }
}
