import React, {Component} from 'react'
import './styles/testscard.css';
import patientJSON from './../assets/data/patientList';
import Paper from "@material-ui/core/Paper";

export default class TestsCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            patientId: props.patientId
        };
    }

    render() {
        const patient = patientJSON[this.state.patientId];
        return (
            <Paper onClick={()=>{this.props.onClick()}} style={{height:100}}>
                Add Prescription for {patient.name}
            </Paper>
        );
    }
}
