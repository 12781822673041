import React, {Component} from 'react'
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import './styles/landing.css';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PatientCard from "../components/PatientCard";
import Avatar from "@material-ui/core/Avatar";
import image2 from "../assets/data/doctor.jpg"
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';

// import AppBar, {FlexibleSpace} from '@material-ui-scrolling-techniques/AppBar';


let a = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
let patients = [1, 2, 3, 4, 5];
const doctor = {
    name: "Dr. Lisa",
    field: "Orthopedic",
    hospital: "Vinay-Vishal Hospital",
    session: "Morning OPD",
};


class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: 4,
            height: window.innerHeight,
        };
    }

    render() {
        const dates = a.map((date) => {
            return (
                <Tab onClick={() => {
                    this.setState({currentDate: date})
                }} label={date}>
                    {date} </Tab>
            )
        });
        const patientsCards = patients.map((patient) => {
            return (
                <PatientCard patientId={patient}/>
            )
        });
        return (
            <div style={{height: 'auto'}}>
                <Paper square={true}
                       style={{
                           textAlign: 'center',
                           backgroundImage: "linear-gradient(#1665c1, #2886ee)",
                           height: 175,
                           maxHeight: '100%',
                           overflow: 'auto'
                       }}>
                    <Typography
                        align="center"
                        color='#FFFFFF'
                        variant="h3">
                        <font color="#FFFFFF" >
                            Farmako
                        </font>
                    </Typography>
                    <Paper id="qwerty" style={{backgroundColor: '#FFFFFF'}}>
                        <Grid container style={{height: '100%', padding: '10px'}} justify="center">
                            <Grid style={{margin: 'auto'}} sm={2} component='div'>
                                <Avatar style={{margin: 'auto', width: 80, height: 80}} className="doctorAvatar"
                                        alt={doctor.name} src={image2} component='div'/>
                            </Grid>
                            <Grid sm={4}>
                                <h3 style={{color: 'black'}}>{doctor.name}</h3>
                            </Grid>
                            <Grid sm={6} component='div'>
                                <Box p={2} ml={4} bgcolor="background.paper">

                                    <Typography>
                                        Specialization: {doctor.field}
                                    </Typography>
                                    <Typography>
                                        Hospital: {doctor.hospital}
                                    </Typography>
                                    <Typography>
                                        Session: {doctor.session}
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Paper>
                <Paper id="bottomHalf">

                    <div style={{overflow: "auto"}}>
                        <Tabs
                            value={this.state.currentDate - 1}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example">
                            {dates}
                        </Tabs>
                    </div>
                    <div>
                        {patientsCards}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default Landing;
